<template>
  <div>
    Statistics
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>

</style>
